"use client"
import type { CSSProperties } from "react"
import { Suspense, useEffect, useState } from "react"
import Image from "next/image"
import { BannerShipping } from "./BannerShipping"
import { TopBar } from "./TopBar"
import { classNames, isBrowser } from "@local/utils"
import { MenuDesktop, MenuMobile } from "./Menus"
import type { Payload } from "@local/payload-client/src/types"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { isInViewport } from "../../isInViewport"
import Cookies from "js-cookie"
import DynamicFaviconAndTitleClient from "@local/ui/src/DynamicFaviconAndTitle"
import dynamic from "next/dynamic"
import { BtnBuy } from "../BtnBuy"
import { useI10n } from "@local/i10n"
import { ITEM_BRAND } from "../../const"
import useRunOnce from "../../hooks/useRunOnce"
import { useTracking } from "@local/tracking"
import { AnchorButton } from "@local/ui"

const BannerLaunch = dynamic(() => import("./BannerLaunch"), { ssr: false })

const XMarkIcon = ({
  className,
  style,
}: {
  className?: string
  style?: CSSProperties
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    aria-hidden="true"
    className={className}
    style={style}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
)

const Bars3Icon = ({
  className,
  style,
}: {
  className?: string
  style?: CSSProperties
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    aria-hidden="true"
    className={className}
    style={style}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
    />
  </svg>
)

interface Props {
  categories?: Map<string, string> | null
  CtaURL?: string
  data: Payload.Anchor
  hideBuyButton?: boolean
  hidePromo?: boolean
  isMirror?: boolean
  isAmbassador?: boolean
  minimal?: boolean
  noDiscount?: boolean
  notHome?: boolean
  skipViewCta?: boolean
  slug?: string | null
  topDiscount?: number | null
}

export const Header = ({
  categories,
  CtaURL,
  data,
  hideBuyButton,
  hidePromo,
  isAmbassador,
  isMirror,
  minimal,
  noDiscount,
  notHome,
  skipViewCta,
  slug,
  topDiscount,
}: Props) => {
  const favicon = data.favicon?.url
  const backgroundColor = data.headerBgColor
  const callToAction = data.callToAction
  const logo = data.logoDark
  const textColor = data.headerTextColor
  const topBarBackgroundColor = data.topBarBgColor
  const topBarTextColor = data.topBarTextColor

  Cookies?.remove("show-upsell-modal")
  const pathname = usePathname()
  const isB2BPage = pathname?.includes("business")
  const isContactPage = pathname?.includes("contact")
  // useUriHashObserver(1000)
  const specialEvent = data.specialEvent

  const { trackActionBuffered } = useTracking()
  const { currencyCode } = useI10n()

  //tracking
  useRunOnce(() => {
    // TODO: check if we need to send all bucketItems on `items` array or if the main one is fine
    const product = data.product ? data.product[0] : null
    // ensure it's only on main page
    if (!product) return

    const categoryList = [...(product.category as unknown as [string])]

    if (product.categories) {
      categoryList.push(...(product.categories as unknown as Array<string>))
    }

    const categoriesFound: Record<string, string | null> = {}
    const allCategories = []
    let foundIndex = 0

    if (categoryList && categoryList.length > 0) {
      for (let i = 0; i < 5; ) {
        const item = categories?.get(categoryList[i])
        if (item) {
          const index = foundIndex === 0 ? "" : `_${foundIndex + 1}`
          categoriesFound[`item_category${index}`] = item
          allCategories.push(item)
          foundIndex++
        }
        i++
      }
    }

    const item = {
      all_categories: allCategories.join(","),
      item_id: product.id,
      item_name: product.name,
      discount: product.origPrice - product.prices[0].price,
      item_brand: ITEM_BRAND,
      ...categoriesFound,
      item_list_name: `${product?.name} anchor page`, //TODO: reviewer, review this https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag&hl=pt-br#view_item
      price: product.prices[0].price,
    }

    trackActionBuffered("view_item", {
      currency: currencyCode,
      value: product.prices[0].price,
      items: [item],
    })
  }, "product_view_anchor")

  // Snippet to mitigate #anchor URLs not working on first load.
  // Under <a> elements they work fine.
  useEffect(() => {
    let wait: NodeJS.Timeout | null
    if (isBrowser() && window) {
      const hash = window.location.hash
      wait = setTimeout(() => {
        const acceptableAnchors = [
          "innovation",
          "faq",
          "reviews",
          "price",
          "pricecta",
        ]
        if (hash && acceptableAnchors.includes(hash)) {
          const element = document.querySelector(hash)
          if (element && wait) {
            if (isInViewport(element)) {
              clearTimeout(wait)
            } else {
              element.scrollIntoView()
              clearTimeout(wait)
            }
          }
        }
      }, 1000)
    }
    return () => {
      wait
    }
  }, [])

  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)
  const { country } = useI10n()

  const logoHeight = minimal ? 92 : 58
  const logoWidth = logo ? logo.width / (logo.height / logoHeight) : 64

  const DISCOUNT = data.discount ?? 60
  const NO_ALERT = specialEvent === "free45"
  let IS_BLACK_SALE = false
  let text: string | null = null

  switch (specialEvent) {
    case "black":
      text = `BLACK FRIDAY SALE! ${DISCOUNT}% OFF TODAY + FREE SHIPPING + EXTENDED RETURNS!`
      IS_BLACK_SALE = true
      break
    case "earlyblack":
      text = `EARLY BLACK FRIDAY SALE! GET ${DISCOUNT}% OFF TODAY!`
      IS_BLACK_SALE = true
      break
    case "lateblack":
      text = `EXTENDED BLACK FRIDAY SALE! GET ${DISCOUNT}% OFF TODAY!`
      IS_BLACK_SALE = true
      break
    case "free45":
      text = `FREE 45-DAY WEARER TRIAL + FREE & EASY RETURNS 👇`
      IS_BLACK_SALE = true
      break
    case "prime":
      text = `PRIMETIME SALE NOW ON! GET ${DISCOUNT}% OFF TODAY!`
      IS_BLACK_SALE = true
      break
    case "cyber":
      text = `CYBER MONDAY SALE NOW ON! GET ${DISCOUNT}% OFF TODAY!`
      IS_BLACK_SALE = true
      break
    case "summer":
      text = `SUMMER CLEARANCE SALE! GET ${DISCOUNT}% OFF TODAY!`
      IS_BLACK_SALE = true
      break
    default:
      break
  }

  return (
    <>
      <DynamicFaviconAndTitleClient favicon={favicon} />
      {/* Skip Links for better accessibility */}
      <div className="skip-link">
        Skip to <a href="#main-content">content</a> or{" "}
        <a href="#footer">footer</a>
      </div>
      <div
        className="fixed inset-x-0 top-0 z-20"
        style={{
          backgroundColor,
          color: textColor,
        }}
      >
        {!hidePromo && !noDiscount && (
          <div
            className="flex items-center justify-center px-4 text-xs font-bold uppercase font-display sm:text-sm"
            style={{
              backgroundColor: IS_BLACK_SALE
                ? "#272727"
                : topBarBackgroundColor,
              color: IS_BLACK_SALE ? "#FFFFFF" : topBarTextColor,
            }}
          >
            {specialEvent === "launch" ? (
              <BannerLaunch color={topBarTextColor} topDiscount={DISCOUNT} />
            ) : text ? (
              <TopBar>
                {!NO_ALERT && <>🚨</>}
                {text} {!NO_ALERT && <>🚨</>}
              </TopBar>
            ) : (
              <BannerShipping
                country={country}
                showDiscount={!noDiscount}
                topDiscount={topDiscount}
                color={topBarTextColor}
              />
            )}
          </div>
        )}
        <nav className="container py-1 mx-auto">
          <div
            className="flex justify-between h-16"
            style={minimal ? { height: "5rem" } : {}}
          >
            <div className={classNames("flex", minimal ? "w-full" : "")}>
              <div className="flex items-center ml-1 mr-2 lg:hidden">
                {!minimal && (
                  <button
                    className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={toggle}
                  >
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon
                        className="block w-6 h-6"
                        aria-hidden="true"
                        style={{ maxWidth: "35px", maxHeight: "35px" }}
                      />
                    ) : (
                      <Bars3Icon
                        className="block w-6 h-6"
                        aria-hidden="true"
                        style={{ maxWidth: "35px", maxHeight: "35px" }}
                      />
                    )}
                  </button>
                )}
              </div>
              {/* TODO enable/disable via "state loaded" (wrapDato) */}
              {logo && (
                <div className="flex items-center justify-center w-full lg:justify-start">
                  <h1 className="relative my-1 flex flex-shrink-0 rtl:ml-4 rtl:mr-3 max-w-[200px]">
                    <Link href={slug ? `/lp/${slug}` : "/"}>
                      <Image
                        src={logo.url}
                        alt="logo"
                        width={logoWidth}
                        height={logoHeight}
                        data-testid="Logo"
                      />
                    </Link>
                  </h1>
                </div>
              )}
              {!minimal && (
                <MenuDesktop
                  notHome={notHome}
                  slug={slug}
                  isListicle={data.isListicle}
                />
              )}
            </div>

            {!hideBuyButton && !isContactPage && (
              <Suspense>
                <BtnBuy
                  bgColor={data.buttonBgColor}
                  callToAction={callToAction}
                  isMirror={isMirror}
                  noDiscount={noDiscount}
                  skipView={skipViewCta}
                  slug={slug}
                  textColor={data.buttonTextColor}
                  url={
                    isB2BPage
                      ? "https://ask.baerskinhoodie.com/business"
                      : CtaURL
                  }
                />
              </Suspense>
            )}

            {isAmbassador && (
              <div className="hidden sm:block pt-2">
                <AnchorButton
                  href="https://baerskinambassadors.trysaral.com/"
                  className="w-full text-white bg-[#0BA94A] hover:bg-green-600/90 !py-3 transition-colors !font-semibold"
                >
                  Join Our Affiliate Programme
                </AnchorButton>
              </div>
            )}
          </div>
        </nav>
        {open && (
          <MenuMobile
            toggle={toggle}
            notHome={notHome}
            slug={slug}
            isListicle={data.isListicle}
          />
        )}
      </div>
      <div className="mt-24" />
    </>
  )
}
