"use client"
import { useScroll } from "@local/utils/src/useScroll"
import { useEffect } from "react"
import Link from "next/link"
import { AnchorScroll } from "../AnchorScroll"
import { usePageLayout } from "../../usePageLayout"

type NavLink = {
  name: string
  href: string
  component?: never
}
type NavAnchor = { name: string; component: "about"; href?: never }
type NavItem = NavLink | NavAnchor

const navigation: NavItem[] = [
  { name: "FAQ", href: "faq" },
  { name: "Reviews", href: "reviews" },
  { name: "About us", component: "about" },
]

// Create a shared menu item renderer
const MenuItem = ({
  item,
  i,
  notHome,
  slug,
  toggle,
  className,
}: {
  item: NavItem
  i: number
  notHome?: boolean
  slug?: string | null
  toggle?: () => void
  className: string
}) => {
  const { togglePage } = usePageLayout()

  if (notHome) {
    return (
      <Link
        key={`link-${item.name}-${i}`}
        href={slug ? `/lp/${slug}/#${item.href}` : `/#${item.href}`}
        onClick={toggle}
        className={className}
      >
        {item.name}
      </Link>
    )
  }

  if (item.href) {
    return (
      <AnchorScroll
        key={`anchor-${item.name}-${i}`}
        to={item.href}
        onClick={toggle}
        offset={-90}
        className={className}
      >
        {item.name}
      </AnchorScroll>
    )
  }

  return (
    <button
      key={`about-${item.name}`}
      onClick={() => {
        toggle?.()
        togglePage("about")
      }}
      data-cy="about"
      className={className}
    >
      About us
    </button>
  )
}

// Extract common navigation items into a shared component
const NavigationItems = ({
  notHome,
  slug,
  isListicle,
  toggle,
  isMobile,
}: {
  notHome?: boolean
  slug?: string | null
  isListicle?: boolean | null
  toggle?: () => void
  isMobile?: boolean
}) => {
  return (
    <>
      {!isListicle &&
        (notHome ? (
          <Link
            href={slug ? `/lp/${slug}/#innovation` : `/#innovation`}
            className="rounded-md px-3 py-2 font-medium"
          >
            Our Innovation
          </Link>
        ) : (
          <AnchorScroll
            to="innovation"
            className="rounded-md px-3 py-2 font-medium"
            offset={-90}
          >
            Our Innovation
          </AnchorScroll>
        ))}
      {navigation.map((item, i) => (
        <MenuItem
          key={`Menu-${isMobile ? "Mobile" : "Desktop"}-${i}`}
          item={item}
          i={i}
          notHome={notHome}
          slug={slug}
          toggle={toggle}
          className={
            isMobile
              ? "block w-full rounded-md px-4 py-2 text-left font-medium rtl:text-right"
              : "rounded-md px-3 py-2 font-medium hover:underline"
          }
        />
      ))}
    </>
  )
}

export const MenuDesktop = ({
  notHome,
  slug,
  isListicle,
}: {
  notHome?: boolean
  slug?: string | null
  isListicle?: boolean | null
}) => (
  <div className="hidden whitespace-nowrap lg:ml-6 lg:flex lg:items-center lg:space-x-4">
    <NavigationItems
      notHome={notHome}
      slug={slug}
      isListicle={isListicle}
      isMobile={false}
    />
  </div>
)

export const MenuMobile = ({
  toggle,
  notHome,
  slug,
  isListicle,
}: {
  toggle: () => void
  notHome?: boolean
  slug?: string | null
  isListicle?: boolean | null
}) => {
  const { scrollDirection } = useScroll()

  useEffect(() => {
    if (scrollDirection) {
      toggle()
    }
  }, [scrollDirection, toggle])

  return (
    <div className="lg:hidden">
      <div className="container mx-auto space-y-1 pb-3 pt-2">
        <NavigationItems
          notHome={notHome}
          slug={slug}
          isListicle={isListicle}
          toggle={toggle}
          isMobile={true}
        />
      </div>
    </div>
  )
}
